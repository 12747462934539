var lang = new Lang({
    messages: {
        'zh_bak.common': {
            'error': '錯誤',
            'login_first': '請先登入。',
            'alert': '提示',
            'failure': '失敗',
            'success': '成功',
            'user_not_found': '找不到用戶',
            'session_expired': '登入狀態逾期。',
            'something_wrong': '發生錯誤，請重試。',
            'ok': '確定',
            'cancel': '取消',
            'close': '關閉',
            'loader_text': '請稍等...',
            'confirm_address': '請先確認地址',
            'fill_address': '請輸入地址',
            'fill_name': '請輸入收件人',
            'fill_phone': '請輸入聯絡號碼',
            'cancel_confirm': '確定要取消？',
            'accept_confirm': '確定要接受這份需求？',
            'decline_confirm': '確定要拒絕這份需求？',
            'confirm_delete': '確定要删除？',
            'is_good': '{0} 損壞|{1} 良好',
            'pallet_retrieving_type': '{1} 以箱出倉 | {2} 棧板出倉',
            'forgot_pw_alert': '忘記密碼了嗎？<br>請聯繫Boxful客服團隊！<br>電話：0800-050-777',
            'confirm_delete': '確定要刪除?',
            'edit_address': '通訊錄',
            'add_address': '新增聯絡人',
            'import_address': '匯入聯絡人清單',
            'cannot_delete': '至少需要一個預約資訊',
            'need_save_others': '請先保存其他預約資訊',
            'fill_date': '請輸入日期',
            'choose_timeslot': '請選擇時段',
            'choose_items': '請至少選取一棧板／箱／件',
            'item_max_qty': '已經選取了所有棧板／箱／件',
            'confirm_item_less_qty': '你還沒選取全部棧板／箱／件，是否確認送出？',
            'plan_unassigned': '有客戶尚未被指定計費方案！',
            'add_new_retailer': '新增子帳號',
            'search_result': '搜尋結果',
            'add_goods': '新增商品',
            'edit_goods': '編輯商品',
            'import_goods': '匯入商品',
            'import_pics': 'Import Products Photos',
            'add_def': '新增出貨方式',
            'edit_def': '編輯出貨方式',
            'add_bundles': '新增商品組合',
            'edit_bundles': '編輯商品組合',
            'edit_bundles_cant_delete': '至少需要一個商品',
            'shopline_notice': '請確認在訂單匯出頁面選取「訂單」、「出貨」、「商品」中的所有欄位，以確保訂單匯入無誤。',
            'acception': '接受拉貨申請',
            'history': '拉貨需求內容',
            'apply_return_result': '申請退貨',
            'attachment_result': '附件管理',
            'apply_reschedule_result': '申請變更出貨日期',
            'resend_confirm': '請確認是否抽單？',
            'logistics_log': '物流紀錄',
            'edit':'Edit',
            'view_details': 'View Details',
        },
        'zh.common': {
            'error': 'Error',
            'login_first': 'Please login first。',
            'alert': 'Alert',
            'failure': 'Failure',
            'success': 'Success',
            'user_not_found': 'User not found',
            'session_expired': 'Login session expired.',
            'something_wrong': 'Something went wrong, please try again later.',
            'ok': 'OK',
            'cancel': 'Cancel',
            'close': 'Close',
            'loader_text': 'Please Wait ...',
            'confirm_address': 'Please confirm the address',
            'fill_address': 'Please fill in the address',
            'fill_name': '請輸入收件人',
            'fill_phone': '請輸入聯絡號碼',
            'need_save_others': 'Please save schedule details',
            'cannot_delete': 'Please schedule at least one delivery.',
            'fill_date': 'Please fill in the date',
            'cancel_confirm': 'Are you sure to cancel？',
            'confirm_delete': 'Are you sure to delete?',
            'accept_confirm': 'Confirm accept request?',
            'decline_confirm': 'Confirm reject request?',
            'is_good': '{0} Bad|{1} Good',
            'pallet_retrieving_type': '{1} Item Out | {2} Pallet Out',
            'forgot_pw_alert': '忘記密碼了嗎？<br>請聯繫Boxful客服團隊！<br>電話：0800-050-777',
            'confirm_delete': 'Are you sure to delete?',
            'edit_address': 'Edit Address',
            'plan_unassigned': 'There are some users have not been assigned plan yet!',
            'add_new_retailer': 'Add Sub Account',
            'search_result': 'Search Result',
            'add_goods': 'Add New Product',
            'edit_goods': 'Edit Product',
            'import_goods': 'Import Products and Outbound Method',
            'import_pics': 'Import Products Photos',
            'add_def': 'Add Outbound Method',
            'edit_def': 'Edit Outbound Method',
            'add_bundles': 'Add Product Bundle',
            'edit_bundles': 'Edit Product Bundle',
            'edit_bundles_cant_delete': 'At least one product needed!',
            'shopline_notice': '請確認在訂單匯出頁面選取「訂單」、「出貨」、「商品」中的所有欄位，以確保訂單匯入無誤。',
            'section_accpet': 'Partially Accept the Pulling Request',
            'history': 'Pulling Request Detail',
            'apply_return_result': 'Apply for Returns',
            'attachment_result': 'Attachments Management',
            'apply_reschedule_result': 'Reschedule',
            'resend_confirm': 'Please confirm to resend this order.',
            'logistics_log': 'Logistics Log',
            'add_address': 'Add New Contact',
            'import_address': 'Import Contacts List',
            'edit':'Edit',
            'view_details': 'View Details',
            'acception': 'Accept Stock Transfer Request',
            'success':'Success!',
            'choose_items':'Please input the item quantity',
        },
        'en.common': {
            'error': 'Error',
            'login_first': 'Please login first。',
            'alert': 'Alert',
            'failure': 'Failure',
            'success': 'Success',
            'user_not_found': 'User not found',
            'session_expired': 'Login session expired.',
            'something_wrong': 'Something went wrong, please try again later.',
            'ok': 'OK',
            'cancel': 'Cancel',
            'close': 'Close',
            'loader_text': 'Please Wait ...',
            'confirm_address': 'Please confirm the address',
            'fill_address': 'Please fill in the address',
            'fill_name': '請輸入收件人',
            'fill_phone': '請輸入聯絡號碼',
            'cancel_confirm': 'Are you sure to cancel？',
            'confirm_delete': 'Are you sure to delete?',
            'accept_confirm': 'Confirm accept request?',
            'decline_confirm': 'Confirm reject request?',
            'is_good': '{0} Bad|{1} Good',
            'pallet_retrieving_type': '{1} Item Out | {2} Pallet Out',
            'forgot_pw_alert': '忘記密碼了嗎？<br>請聯繫Boxful客服團隊！<br>電話：0800-050-777',
            'confirm_delete': 'Are you sure to delete?',
            'need_save_others': 'Please save schedule details',
            'cannot_delete': 'Please schedule at least one delivery.',
            'fill_date': 'Please fill in the date',
            'edit_address': 'Edit Address',
            'plan_unassigned': 'There are some users have not been assigned plan yet!',
            'add_new_retailer': 'Add Sub Account',
            'search_result': 'Search Result',
            'add_goods': 'Add New Product',
            'edit_goods': 'Edit Product',
            'import_goods': 'Import Products and Outbound Method',
            'import_pics': 'Import Products Photos',
            'add_def': 'Add Outbound Method',
            'edit_def': 'Edit Outbound Method',
            'add_bundles': 'Add Product Bundle',
            'edit_bundles': 'Edit Product Bundle',
            'edit_bundles_cant_delete': 'At least one product needed!',
            'shopline_notice': '請確認在訂單匯出頁面選取「訂單」、「出貨」、「商品」中的所有欄位，以確保訂單匯入無誤。',
            'section_accpet': 'Partially Accept the Pulling Request',
            'history': 'Pulling Request Detail',
            'apply_return_result': 'Apply for Returns',
            'attachment_result': 'Attachments Management',
            'apply_reschedule_result': 'Reschedule',
            'resend_confirm': 'Please confirm to resend this order.',
            'logistics_log': 'Logistics Log',
            'add_address': 'Add New Contact',
            'import_address': 'Import Contacts List',
            'edit':'Edit',
            'view_details': 'View Details',
            'acception': 'Accept Stock Transfer Request',
            'success':'Success!',
            'choose_items':'Please input the item quantity',
        },
        'zh.retrieval':{
            'fill_custom_order_id' : "Please input customised order number!",
            'fill_pay_on_arrival' : "Please input amount of COD!",
            'select_logistic': "Please select outbound method!",
            "fill_reciever_name": "Please enter recipient's name!",
            'fill_reciever_phone': "Please input recipient contact number!",
            'fill_region_district': "Please select region!",
            'fill_reciever_address': "Please enter recipient's address!",
            'fill_postal_code': "Please input postal code!",
            'fill_city': "Please input region!",
            'fill_cost': "Please input value of goods!",
            'fill_time_shipment': "Please input time of shipment",
            'header_product_name': "Product Name",
            'header_product_status': "Product Status",
            'header_qty': "Quantity",
            'header_unit': "Unit",
            'header_pallet_out': "Pallet Out",
            'customised_order_number':'Customised Order Number: ',
            'COD':'COD: ',
            'attachments_number':'Number of Attachments: ',
            'country':'Country',
            'province':'Province',
            'city':'City',
            'region':'Region',
            'district':'District',
            'postal_code':'Postal Code',
            'goods_value':'Goods Value',
            'address':'Address',
        },
        'en.retrieval':{
            'fill_custom_order_id' : "Please input customised order number!",
            'fill_pay_on_arrival' : "Please input amount of COD!",
            'select_logistic': "Please select outbound method!",
            "fill_reciever_name": "Please enter recipient's name!",
            'fill_reciever_phone': "Please input recipient contact number!",
            'fill_region_district': "Please select region!",
            'fill_reciever_address': "Please enter recipient's address!",
            'fill_postal_code': "Please input postal code!",
            'fill_city': "Please input region!",
            'fill_cost': "Please input value of goods!",
            'fill_time_shipment': "Please input time of shipment",
            'header_product_name': "Product Name",
            'header_product_status': "Product Status",
            'header_qty': "Quantity",
            'header_unit': "Unit",
            'header_pallet_out': "Pallet Out",
            'customised_order_number':'Customised Order Number: ',
            'COD':'COD: ',
            'attachments_number':'Number of Attachments: ',
            'country':'Country',
            'province':'Province',
            'city':'City',
            'region':'Region',
            'district':'District',
            'postal_code':'Postal Code',
            'goods_value':'Goods Value',
            'address':'Address'

        },
        'zh.unit' :{
            'piece': "piece",
            'pallet': "pallet",
            'item': "item",
            'bundle': "bundle",
        },
        'en.unit':{
            'piece': "piece",
            'pallet': "pallet",
            'item': "item",
            'bundle': "bundle",
        },
        'zh.retailer_history_status':{
            '0': 'Rejected',
            '1': 'Accepted'
        },
        'en.retailer_history_status':{
            '0': 'Rejected',
            '1': 'Accepted'
        },
        'zh_bak.logistic_status': {
            'pending_in': '準備入倉',
            'pending_qc': '準備品質檢查',
            'stored': '已入倉',
            'pending_out': '準備出倉',
            'retrieved': '已出倉',
        },
        'zh.logistic_status': {
            'pending_in': 'Pending In',
            'pending_qc': 'Panding For QC',
            'stored': 'In Storage',
            'pending_out': 'Pending Out',
            'retrieved': 'Retrieved',
        },
        'en.logistic_status': {
            'pending_in': 'Pending In',
            'pending_qc': 'Panding For QC',
            'stored': 'In Storage',
            'pending_out': 'Pending Out',
            'retrieved': 'Retrieved',
        },
        'zh_bak.retrieving_type': {
            '0': '沒定義',
            '1': '以箱出倉',
            '2': '棧板出倉',
            '3': '揀貨出倉',
            '4': '商品組合',
            'piece_out': '揀貨出倉',
            'item_out': '以箱出倉',
            'pallet_out': '棧板出倉',
            'bundle_out': '商品組合',
        },
        'zh.retrieving_type': {
            '0': 'Not Defined',
            '1': 'By Box',
            '2': 'By Pallet',
            '3': 'By Piece',
            '4': 'By Bundle',
            'piece_out': 'By Piece',
            'item_out': 'By Box',
            'pallet_out': 'By Pallet',
            'bundle_out': 'By Bundle',
        },
        'en.retrieving_type': {
            '0': 'Not Defined',
            '1': 'By Box',
            '2': 'By Pallet',
            '3': 'By Piece',
            '4': 'By Bundle',
            'piece_out': 'By Piece',
            'item_out': 'By Box',
            'pallet_out': 'By Pallet',
            'bundle_out': 'By Bundle',
        },
        'zh_bak.notification': {
            'missing_field': '請輸入所需資料',
        },
        'zh.notification': {
            'missing_field': 'Please fill-in all the requried fields',
        },
        'en.notification': {
            'missing_field': 'Please fill-in all the requried fields',
        },
        'zh_bak.admin': {
            'confirm_new_user': '確定要新增一個新的用戶嗎？',
            'saved_user': '已成功儲存用戶: ',
            'added_new_plan': '已成功加入新計劃 ',
            'see_plan': '查看計畫',
            'confirm_cancel_plan': '確定要取消這個計劃嗎？',
            'confirm_complete_plan': '確定要完成這個計劃並且把箱子歸還嗎?',
            'confirm_gen_inv': '確定要將計劃匯入帳單嗎？',
            'confirm_inv_status': '確定要更改帳單狀態嗎？',
            'confirm_inv_item_add': '確定要新增一個帳單項目嗎？',
            'confirm_inv_item_delete': '確定要剛除這個帳單項目嗎？',
            'plan_extend': '續約',
            'plan_upgrade_midway': '中途升級計劃',
            'complete_plan': '完成計劃',
            'pls_fill_in_all_data': '請填入所需資料',
            'confirm_plan_remove': '確定刪除這個計劃嗎？',
            'confirm_plan_quotation_reset': '確定重置這些報價嗎？',
            'confirm_fee_quotation_reset': '確定重置這些附加費嗎？',
            'confirm_create_invoice': '確定要創建新帳單嗎？',
            'confirm_cancel_sch': '確定要取消這個行程?',
            'confirm_update_items_retrieving_type': '確定要更改全部物件的Retrieving Type嗎？',
            'confirm_all_retrieved': '確定要將這張訂單中的所有物件改成「已出倉」嗎？',
        },
        'zh.admin': {
            'confirm_new_user': 'Add a new user, are you sure?',
            'saved_user': 'User Saved: ',
            'added_new_plan': 'New plan added.',
            'see_plan': 'view plans',
            'confirm_cancel_plan': 'Are you sure cancelling this plan?',
            'confirm_complete_plan': 'Are you sure completing this plan & release the locker?',
            'confirm_gen_inv': 'Are you sure generating invoice for the plan(s)?',
            'confirm_inv_status': 'Are you sure updating this invoice\'s status?',
            'confirm_inv_item_add': 'Are you sure adding a new item?',
            'confirm_inv_item_delete': 'Are you sure deleting this item?',
            'plan_extend': 'Plan Extend',
            'plan_upgrade_midway': 'Plan Upgrade Midway',
            'complete_plan': 'Complete Plan',
            'pls_fill_in_all_data': 'Please fill in all the fields',
            'confirm_plan_remove': 'Are you sure removing this plan？',
            'confirm_plan_quotation_reset': 'Are you sure reset this quotation?',
            'confirm_fee_quotation_reset': 'Are you sure reset this quotation?',
            'confirm_create_invoice': 'Are you sure creating a new invoice？',
            'confirm_cancel_sch': 'Are you sure cancelling this schedule?',
            'confirm_update_items_retrieving_type': 'Are you sure to change the Retrieving Type of all items?',
            'confirm_all_retrieved': 'Are you sure to retrieve all items in this order?',
        },
        'en.admin': {
            'confirm_new_user': 'Add a new user, are you sure?',
            'saved_user': 'User Saved: ',
            'added_new_plan': 'New plan added.',
            'see_plan': 'view plans',
            'confirm_cancel_plan': 'Are you sure cancelling this plan?',
            'confirm_complete_plan': 'Are you sure completing this plan & release the locker?',
            'confirm_gen_inv': 'Are you sure generating invoice for the plan(s)?',
            'confirm_inv_status': 'Are you sure updating this invoice\'s status?',
            'confirm_inv_item_add': 'Are you sure adding a new item?',
            'confirm_inv_item_delete': 'Are you sure deleting this item?',
            'plan_extend': 'Plan Extend',
            'plan_upgrade_midway': 'Plan Upgrade Midway',
            'complete_plan': 'Complete Plan',
            'pls_fill_in_all_data': 'Please fill in all the fields',
            'confirm_plan_remove': 'Are you sure removing this plan？',
            'confirm_plan_quotation_reset': 'Are you sure reset this quotation?',
            'confirm_fee_quotation_reset': 'Are you sure reset this quotation?',
            'confirm_create_invoice': 'Are you sure creating a new invoice？',
            'confirm_cancel_sch': 'Are you sure cancelling this schedule?',
            'confirm_update_items_retrieving_type': 'Are you sure to change the Retrieving Type of all items?',
            'confirm_all_retrieved': 'Are you sure to retrieve all items in this order?',
        },
        'zh_bak.qtyForm': {
            'items': '總箱數：',
            'pieces_per_item': '每箱件數：',
            'pallets': '總板數：',
            'items_per_pallet': '每板箱數：',
            'pieces': '總件數：',
            'pieces_per_pallet': '每板件數：',
        },
        'zh.qtyForm': {
            'items': 'Total Boxes: ',
            'pieces_per_item': 'Pieces per Box: ',
            'pallets': 'Total Pallets: ',
            'items_per_pallet': 'Boxes per Pallet: ',
            'pieces': 'Total Pieces: ',
            'pieces_per_pallet': 'Pieces per Pallet: ',
        },
        'en.qtyForm': {
            'items': 'Total Boxes: ',
            'pieces_per_item': 'Pieces per Box: ',
            'pallets': 'Total Pallets: ',
            'items_per_pallet': 'Boxes per Pallet: ',
            'pieces': 'Total Pieces: ',
            'pieces_per_pallet': 'Pieces per Pallet: ',
        },
        'zh.address': {
            'placeholder': 'Please select contact person or fill in below',
        },
        'en.address': {
            'placeholder': 'Please select contact person or fill in below',
        },
        'zh.pickup': {
            'logistics_check_msg': 'Delivery Address will be set as Boxful\'s warehouse if "Use in-house logistics" is selected. Please fill in the contact information of the person-in charge of the delivery. thanks!',
            'container_check_msg': 'Delivery Address will be set as Boxful\'s warehouse if "Devanning Service" is selected. Please fill kn the information of the person on behalf of the logistics, thanks!',
            'custom_check_msg': '',
            'custom_check_pickup':'',
            'origin_pickup':'Delivery Date',
            'no_by_piece_outbound':'Please create an Outbound method (By Piece) from SKU list in order to make an inbound schedule (Manual)',
        },
        'en.pickup': {
            'logistics_check_msg': 'Delivery Address will be set as Boxful\'s warehouse if "Use in-house logistics" is selected. Please fill in the contact information of the person-in charge of the delivery. thanks!',
            'container_check_msg': 'Delivery Address will be set as Boxful\'s warehouse if "Devanning Service" is selected. Please fill kn the information of the person on behalf of the logistics, thanks!',
            'custom_check_msg': '',
            'custom_check_pickup':'',
            'origin_pickup':'Delivery Date',
            'no_by_piece_outbound':'Please create an Outbound method (By Piece) from SKU list in order to make an inbound schedule (Manual)',
        },
        'zh.goods':{
            'view_ob_method':'View Outbound Method',
            'add_ob_method':'Add Outbound Method',
        },
        'en.goods':{
            'view_ob_method':'View Outbound Method',
            'add_ob_method':'Add Outbound Method',
        },
        'zh.bundles':{
            'view_bundles':'View Bundle',
            'increase_decresase_stock':'Increase/Decrease',
            'replace_stock':'Replace',
            'select_item':'Select Item',
        },
        'en.bundles':{
            'view_bundles':'View Bundle',
            'increase_decresase_stock':'Increase/Decrease',
            'replace_stock':'Replace',
            'select_item':'Select Item',
        },
        'en.orders':{
            'queue_status_pending':'Pending',
            'queue_status_processing':'Processing',
            'queue_status_processed_wrong':'Errors found, please click for more details',
            'queue_status_processed':'Completed',
        },
        'zh.orders':{
            'queue_status_pending':'Pending',
            'queue_status_processing':'Processing',
            'queue_status_processed_wrong':'Errors found, please click for more details',
            'queue_status_processed':'Completed',
        },

    }
});
